'use strict';
/* Index ----------------------------------------

* Variables
* Parallax
* Smooth scroll
* Hamburger navigation for mobile
* Scroll animation

------------------------------------------------- */
/* ----------------------------------------------
  Variables
------------------------------------------------- */
var bodyEl = document.body;
var doc = document.documentElement;
var root = getComputedStyle(doc);
// Id & Class
var header_id = 'js-header';
var hambIcon_id = 'js-hamburger';
// const overlay_id          = 'js-overlay';
// const accordion_trigger   = 'accordion-trigger';
var active_class = 'active';
var opened_class = 'opened';
var closed_class = 'closed';
var input_class = '.js-input'; // フォーカスした時に下線を表示させる対象のinput要素のclass
var input_line_class = 'input__line'; // input要素に下線を表示させるためのspan要素のclass
var works = [
    'sendaiforstartups2020',
    'furusatopalette',
    'bluedotgreen'
];
(function () {
    // 最初に、ビューポートの高さを取得し、0.01を掛けて1%の値を算出して、vh単位の値を取得
    var vh = window.innerHeight * 0.01;
    setVerticalHeight(vh);
    function setVerticalHeight(vh) {
        // カスタム変数--vhの値をドキュメントのルートに設定
        document.documentElement.style.setProperty('--vh', vh + "px");
    }
    // resizeイベントの取得
    window.addEventListener('resize', function () {
        vh = window.innerHeight * 0.01;
        setVerticalHeight(vh);
    });
})();
/* ----------------------------------------------
  Typeform - popup
------------------------------------------------- */
function contact() {
    var popup = document.getElementById('js-popup');
    popup.classList.add('active');
}
/* ----------------------------------------------
  Typeform
------------------------------------------------- */
(function () {
    var qs, js, q, s, d = document, gi = d.getElementById, ce = d.createElement, gt = d.getElementsByTagName, id = "typef_orm_share", b = "https://embed.typeform.com/";
    if (!gi.call(d, id)) {
        js = ce.call(d, "script");
        js.id = id;
        js.src = b + "embed.js";
        q = gt.call(d, "script")[0];
        q.parentNode.insertBefore(js, q);
    }
})();
/* ----------------------------------------------
  Works pager
------------------------------------------------- */
(function () {
    var link_prev = document.getElementById('js-pager-prev');
    var link_next = document.getElementById('js-pager-next');
    var currentUrl = window.location.href.split('/');
    var directory = currentUrl[currentUrl.length - 2];
    for (var i = 0; i < works.length; i++) {
        if (directory === works[i]) {
            // set prev link
            if (link_prev)
                link_prev.href = '/works/' + works[i - 1] + '/';
            // set next link
            if (link_next)
                link_next.href = '/works/' + works[i + 1] + '/';
        }
    }
})();
/* ----------------------------------------------
  GSAP
------------------------------------------------- */
// gsap.to('.a', {
//   x: 400,
//   rotation: 360,
//   duration: 3
// })
/* ----------------------------------------------
  Smooth scroll
------------------------------------------------- */
// ページ内リンクをクリックした時に、滑らかにスクロールさせる
// (function () {
//   var speed = 500;
//   var urlHash = window.location.hash;
//   // direct browser to top right away
//   if (urlHash) {
//     scroll(0, 0);
//     // takes care of some browsers issue
//     setTimeout(function(){scroll(0,0);},1);
//   }
//   $('a[href^="#"]').on('click',function(e){
//     e.preventDefault();
//     var href= $(this).attr("href");
//     $('html, body').stop().animate({ scrollTop: $(href).offset().top }, speed, 'swing');
//   });
//   if(urlHash){
//     $('html, body').animate({ scrollTop:$(urlHash).offset().top + 'px' }, speed, 'swing');
//   }
// }());
/* ----------------------------------------------
  Hamburger navigation for mobile
------------------------------------------------- */
(function () {
    var nav = document.getElementById(header_id);
    var hambIcon = document.getElementById(hambIcon_id);
    // ハンバーガーアイコンをクリックすると、メニューを開く
    hambIcon.addEventListener('click', function (e) {
        this.classList.toggle(active_class);
        nav.classList.toggle(active_class);
        e.preventDefault();
    });
    // メニューのリンクかオーバーレイをクリックすると、メニューを閉じる
    // var elms = Array.from(nav.getElementsByTagName('a'));
    // elms.push(nav);
    // elms.forEach(elm => {
    //   elm.addEventListener('click', function() {
    //     hambIcon.classList.remove(active_class);
    //     nav.classList.remove(active_class);
    //   });
    // });
}());
